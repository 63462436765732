import { useState } from "react";
import happiness from "./happiness";
import classNames from "classnames";
const percentage = require("calculate-percentages");

function App() {
  const [openGroups, setOpenGroups] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);

  const handleGroupClick = group => {
    if (openGroups.includes(group.name)) {
      setOpenGroups(openGroups.filter(groupName => groupName !== group.name));
    } else {
      setOpenGroups(openGroups.concat([group.name]));
    }
  };

  const levels = happiness.map(group => ({
    name: group.name,
    complete: group.items.filter(i => checkedItems.includes(i.name)).length,
    total: group.items.length,
  }));

  const percentageFor = group => {
    const level = levels.filter(l => l.name === group.name)[0];
    if (level.complete === 0) {
      return 0;
    }
    return Math.round(percentage.calculate(level.complete, level.total));
  };

  const handleItemClick = item => {
    if (checkedItems.includes(item.name)) {
      setCheckedItems(checkedItems.filter(itemName => itemName !== item.name));
    } else {
      setCheckedItems(checkedItems.concat([item.name]));
    }
  };

  const classForPercentage = percentage => {
    if (percentage > 50) {
      return "border-green-300";
    } else if (percentage > 20) {
      return "border-yellow-300";
    } else {
      return "border-red-300";
    }
  };

  return (
    <div className="container mx-auto p-3 max-w-2xl h-full">
      <div className="text-gray-700 text-5xl mb-5">Happiness</div>
      <div className="text-gray-500 text-3xl mb-5">
        How to hack your happiness
      </div>

      <div className="space-y-1">
        {happiness.map(group => (
          <div key={group.name} className="space-y-1">
            <button
              key={group.name}
              onClick={() => handleGroupClick(group)}
              className={classNames(
                "block w-full border-4 outline-none border-gray-100 rounded-lg p-3 text-4-xl font-bold text-gray-700",
                classForPercentage(percentageFor(group))
              )}
            >
              <div className="flex items-center">
                <div className="h-4 w-4 mr-2">
                  {openGroups.includes(group.name) ? (
                    <svg
                      aria-hidden="true"
                      role="img"
                      className="w-full h-full"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M7.41 8.58L12 13.17l4.59-4.59L18 10l-6 6l-6-6l1.41-1.42z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      aria-hidden="true"
                      role="img"
                      className="w-full h-full"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M8.59 16.58L13.17 12L8.59 7.41L10 6l6 6l-6 6l-1.41-1.42z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  )}
                </div>
                <div className="flex-grow text-left">{group.name}</div>
                <div className="text-right">&nbsp;{percentageFor(group)}%</div>
              </div>
            </button>
            {openGroups.includes(group.name) && (
              <div className="space-y-1 ml-4">
                {group.items.map(item => (
                  <button
                    key={item.name}
                    onClick={() => handleItemClick(item)}
                    className={classNames(
                      "flex w-full items-center block border-4 rounded-lg p-3 text-4-xl font-bold outline-none",
                      checkedItems.includes(item.name)
                        ? "text-gray-700 border-green-300"
                        : "text-gray-700 border-gray-100"
                    )}
                  >
                    <div className="h-4 w-4 mr-2">{item.icon}</div>
                    <div className="">{item.name}</div>
                  </button>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
