const happiness = [
  {
    name: "dopamine",
    description: "the reward chemical",
    items: [
      {
        name: "Complete a task",
        icon: (
          <svg
            aria-hidden="true"
            role="img"
            className="w-full h-full"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M16.5 11L13 7.5l1.4-1.4l2.1 2.1L20.7 4l1.4 1.4l-5.6 5.6M11 7H2v2h9V7m10 6.4L19.6 12L17 14.6L14.4 12L13 13.4l2.6 2.6l-2.6 2.6l1.4 1.4l2.6-2.6l2.6 2.6l1.4-1.4l-2.6-2.6l2.6-2.6M11 15H2v2h9v-2z"
              fill="currentColor"
            ></path>
          </svg>
        ),
      },
      {
        name: "Do self-care activities",
        icon: (
          <svg
            aria-hidden="true"
            role="img"
            className="w-full h-full"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M15.5 9.63c-.19-2.79-1.32-5.51-3.44-7.63A12.253 12.253 0 0 0 8.5 9.63c1.29.68 2.47 1.56 3.5 2.63a14.22 14.22 0 0 1 3.5-2.63M12 15.45C9.85 12.17 6.18 10 2 10c0 10 9.32 11.89 10 12c.68-.12 10-2 10-12c-4.18 0-7.85 2.17-10 5.45z"
              fill="currentColor"
            ></path>
          </svg>
        ),
      },
      {
        name: "Eat some food",
        icon: (
          <svg
            aria-hidden="true"
            role="img"
            className="w-full h-full"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M20 10c2 3-3 12-5 12s-2-1-3-1s-1 1-3 1s-7-9-5-12s5-3 7-2V5C5.38 8.07 4.11 3.78 4.11 3.78S6.77.19 11 5V3h2v5c2-1 5-1 7 2z"
              fill="currentColor"
            ></path>
          </svg>
        ),
      },
      {
        name: "Celebrate the little wins",
        icon: (
          <svg
            aria-hidden="true"
            role="img"
            className="w-full h-full"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M14.53 1.45l-1.08 1.08l1.6 1.6c.22.25.33.54.33.87s-.11.64-.33.86L11.5 9.47l1 1.08l3.63-3.61c.53-.59.79-1.24.79-1.94s-.26-1.36-.79-1.95l-1.6-1.6m-3.98 2.02L9.47 4.55l.61.56c.22.22.33.52.33.89s-.11.67-.33.89l-.61.56l1.08 1.08l.56-.61c.53-.59.8-1.23.8-1.92c0-.72-.27-1.37-.8-1.97l-.56-.56M21 5.06c-.69 0-1.33.27-1.92.8l-5.63 5.64l1.08 1l5.58-5.56c.25-.25.55-.38.89-.38s.64.13.89.38l.61.61l1.03-1.08l-.56-.61c-.59-.53-1.25-.8-1.97-.8M7 8L2 22l14-5l-9-9m12 3.06c-.7 0-1.34.27-1.94.8l-1.59 1.59l1.08 1.08l1.59-1.59c.25-.25.53-.38.86-.38c.33 0 .63.13.88.38l1.62 1.59l1.05-1.03l-1.6-1.64c-.59-.53-1.25-.8-1.95-.8z"
              fill="currentColor"
            ></path>
          </svg>
        ),
      },
    ],
  },
  {
    name: "oxytocin",
    description: "the love hormone",
    items: [
      {
        name: "Play with a pet",
        icon: (
          <svg
            aria-hidden="true"
            role="img"
            className="w-full h-full"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M19 3l-4 4l3 3l1-1l1 1l2-2l-3-3V3M3 7L2 8l3 3v3l-1 1v6h2v-3l2-3h7v6h2V11l-3-3l-1 1H5L3 7z"
              fill="currentColor"
            ></path>
          </svg>
        ),
      },
      {
        name: "Play with a baby",
        icon: (
          <svg
            aria-hidden="true"
            role="img"
            className="w-full h-full"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 2a3 3 0 0 1 3 3a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3m-1 20H8v-6H6V9h12v7h-2v6h-3v-4h-2v4z"
              fill="currentColor"
            ></path>
          </svg>
        ),
      },
      {
        name: "Hold hands",
        icon: (
          <svg
            aria-hidden="true"
            role="img"
            className="w-full h-full"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M11 6h3l3.29-3.3a1 1 0 0 1 1.42 0l2.58 2.59a1 1 0 0 1 0 1.41L19 9h-8v2a1 1 0 0 1-1 1a1 1 0 0 1-1-1V8a2 2 0 0 1 2-2m-6 5v4l-2.29 2.29a1 1 0 0 0 0 1.41l2.58 2.59a1 1 0 0 0 1.42 0L11 17h4a1 1 0 0 0 1-1v-1h1a1 1 0 0 0 1-1v-1h1a1 1 0 0 0 1-1v-1h-7v1a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2V9z"
              fill="currentColor"
            ></path>
          </svg>
        ),
      },
      {
        name: "Hug your family",
        icon: (
          <svg
            aria-hidden="true"
            role="img"
            className="w-full h-full"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M7.5 2a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2M6 22v-6H3l2.6-7.6c.3-.8 1-1.4 1.9-1.4c.9 0 1.7.6 1.9 1.4L12 16l2.6-7.6c.3-.8 1-1.4 1.9-1.4c.9 0 1.7.6 1.9 1.4L21 16h-3v6h-3v-6H9v6H6M16.5 2a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2z"
              fill="currentColor"
            ></path>
          </svg>
        ),
      },
      {
        name: "Give a compliment",
        icon: (
          <svg
            aria-hidden="true"
            role="img"
            className="w-full h-full"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 3c5.5 0 10 3.58 10 8s-4.5 8-10 8c-1.24 0-2.43-.18-3.53-.5C5.55 21 2 21 2 21c2.33-2.33 2.7-3.9 2.75-4.5C3.05 15.07 2 13.13 2 11c0-4.42 4.5-8 10-8z"
              fill="currentColor"
            ></path>
          </svg>
        ),
      },
    ],
  },
  {
    name: "serotonin",
    description: "the mood stabilizer",
    items: [
      {
        name: "Meditate",
        icon: (
          <svg
            aria-hidden="true"
            role="img"
            className="w-full h-full"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 4c1.11 0 2 .89 2 2s-.89 2-2 2s-2-.89-2-2s.9-2 2-2m9 12v-2c-2.24 0-4.16-.96-5.6-2.68l-1.34-1.6A1.98 1.98 0 0 0 12.53 9H11.5c-.61 0-1.17.26-1.55.72l-1.34 1.6C7.16 13.04 5.24 14 3 14v2c2.77 0 5.19-1.17 7-3.25V15l-3.88 1.55c-.67.27-1.12.95-1.12 1.66C5 19.2 5.8 20 6.79 20H9v-.5a2.5 2.5 0 0 1 2.5-2.5h3c.28 0 .5.22.5.5s-.22.5-.5.5h-3c-.83 0-1.5.67-1.5 1.5v.5h7.21c.99 0 1.79-.8 1.79-1.79c0-.71-.45-1.39-1.12-1.66L14 15v-2.25c1.81 2.08 4.23 3.25 7 3.25z"
              fill="currentColor"
            ></path>
          </svg>
        ),
      },
      {
        name: "Go for a run",
        icon: (
          <svg
            aria-hidden="true"
            role="img"
            className="w-full h-full"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M16.5 5.5a2 2 0 0 0 2-2a2 2 0 0 0-2-2a2 2 0 0 0-2 2a2 2 0 0 0 2 2m-3.6 13.9l1-4.4l2.1 2v6h2v-7.5l-2.1-2l.6-3A7.298 7.298 0 0 0 22 13v-2c-1.76.03-3.4-.89-4.3-2.4l-1-1.6c-.36-.6-1-1-1.7-1c-.3 0-.5.1-.8.1L9 8.3V13h2V9.6l1.8-.7l-1.6 8.1l-4.9-1l-.4 2l7 1.4M4 9a1 1 0 0 1-1-1a1 1 0 0 1 1-1h3v2H4m1-4a1 1 0 0 1-1-1a1 1 0 0 1 1-1h5v2H5m-2 8a1 1 0 0 1-1-1a1 1 0 0 1 1-1h4v2H3z"
              fill="currentColor"
            ></path>
          </svg>
        ),
      },
      {
        name: "Sun exposure",
        icon: (
          <svg
            aria-hidden="true"
            role="img"
            className="w-full h-full"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0-7l2.39 3.42C13.65 5.15 12.84 5 12 5c-.84 0-1.65.15-2.39.42L12 2M3.34 7l4.16-.35A7.2 7.2 0 0 0 5.94 8.5c-.44.74-.69 1.5-.83 2.29L3.34 7m.02 10l1.76-3.77a7.131 7.131 0 0 0 2.38 4.14L3.36 17M20.65 7l-1.77 3.79a7.023 7.023 0 0 0-2.38-4.15l4.15.36m-.01 10l-4.14.36c.59-.51 1.12-1.14 1.54-1.86c.42-.73.69-1.5.83-2.29L20.64 17M12 22l-2.41-3.44c.74.27 1.55.44 2.41.44c.82 0 1.63-.17 2.37-.44L12 22z"
              fill="currentColor"
            ></path>
          </svg>
        ),
      },
      {
        name: "Walk in nature",
        icon: (
          <svg
            aria-hidden="true"
            role="img"
            className="w-full h-full"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M14.12 10H19V8.2h-3.62l-2-3.33c-.3-.5-.84-.84-1.46-.84c-.18 0-.34.03-.5.08L6 5.8V11h1.8V7.33l2.11-.66L6 22h1.8l2.87-8.11L13 17v5h1.8v-6.41l-2.49-4.54l.73-2.87M14 3.8c1 0 1.8-.8 1.8-1.8S15 .2 14 .2S12.2 1 12.2 2S13 3.8 14 3.8z"
              fill="currentColor"
            ></path>
          </svg>
        ),
      },
      {
        name: "Swimming",
        icon: (
          <svg
            aria-hidden="true"
            role="img"
            className="w-full h-full"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M2 18c2.22-1 4.44-2 6.67-2c2.22 0 4.44 2 6.66 2c2.23 0 4.45-2 6.67-2v3c-2.22 0-4.44 2-6.67 2c-2.22 0-4.44-2-6.66-2c-2.23 0-4.45 1-6.67 2v-3m6.67-5c-.78 0-1.55.12-2.32.32l4.92-3.44l-1.04-1.24c-.14-.17-.23-.4-.23-.64c0-.34.17-.65.44-.83l5.72-4l1.15 1.63l-4.84 3.39l5.23 6.23c-.79.33-1.58.58-2.37.58c-2.22 0-4.44-2-6.66-2M18 7a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2z"
              fill="currentColor"
            ></path>
          </svg>
        ),
      },
      {
        name: "Cycling",
        icon: (
          <svg
            aria-hidden="true"
            role="img"
            className="w-full h-full"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M19 10c-.56 0-1.09.11-1.59.28L14.46 4.5H11V6h2.54l.88 1.72L12 13.13l-1.77-4.18c.27-.1.51-.37.51-.7c0-.41-.33-.75-.74-.75H8c-.42 0-.76.34-.76.75S7.58 9 8 9h.61l2.25 5.25h-.94C9.56 11.85 7.5 10 5 10c-2.76 0-5 2.24-5 5s2.24 5 5 5c2.5 0 4.56-1.85 4.92-4.25h2.58l2.79-6.32l.79 1.53A4.976 4.976 0 0 0 14 15c0 2.76 2.24 5 5 5s5-2.24 5-5s-2.24-5-5-5M5 18.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5c1.67 0 3.07 1.18 3.41 2.75H4v1.5h4.41A3.495 3.495 0 0 1 5 18.5m14 0c-1.93 0-3.5-1.57-3.5-3.5c0-1.08.5-2.03 1.27-2.67l1.8 3.52l1.32-.72l-1.79-3.5c.29-.07.59-.13.9-.13c1.93 0 3.5 1.57 3.5 3.5s-1.57 3.5-3.5 3.5z"
              fill="currentColor"
            ></path>
          </svg>
        ),
      },
    ],
  },
  {
    name: "endorphin",
    description: "the pain killer",
    items: [
      {
        name: "Laughter exercise",
        icon: (
          <svg
            aria-hidden="true"
            role="img"
            className="w-full h-full"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M20 12a8 8 0 0 0-8-8a8 8 0 0 0-8 8a8 8 0 0 0 8 8a8 8 0 0 0 8-8m2 0a10 10 0 0 1-10 10A10 10 0 0 1 2 12A10 10 0 0 1 12 2a10 10 0 0 1 10 10M10 9.5c0 .8-.7 1.5-1.5 1.5S7 10.3 7 9.5S7.7 8 8.5 8s1.5.7 1.5 1.5m7 0c0 .8-.7 1.5-1.5 1.5S14 10.3 14 9.5S14.7 8 15.5 8s1.5.7 1.5 1.5m-5 7.73c-1.75 0-3.29-.73-4.19-1.81L9.23 14c.45.72 1.52 1.23 2.77 1.23s2.32-.51 2.77-1.23l1.42 1.42c-.9 1.08-2.44 1.81-4.19 1.81z"
              fill="currentColor"
            ></path>
          </svg>
        ),
      },
      {
        name: "Essential oils",
        icon: (
          <svg
            aria-hidden="true"
            role="img"
            className="w-full h-full"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M13 4h-2l-1-2h4l-1 2m6 9v9H5v-9c0-2.76 2.24-5 5-5V6H9V5h6v1h-1v2c2.76 0 5 2.24 5 5m-2 0c0-1.65-1.35-3-3-3h-4c-1.65 0-3 1.35-3 3v7h10v-7z"
              fill="currentColor"
            ></path>
          </svg>
        ),
      },
      {
        name: "Watch a comedy",
        icon: (
          <svg
            aria-hidden="true"
            role="img"
            className="w-full h-full"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M8.16 3L6.75 4.41L9.34 7H4c-1.11 0-2 .89-2 2v10c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V9c0-1.11-.89-2-2-2h-5.34l2.59-2.59L15.84 3L12 6.84L8.16 3M4 9h13v10H4V9m15.5 0a1 1 0 0 1 1 1a1 1 0 0 1-1 1a1 1 0 0 1-1-1a1 1 0 0 1 1-1m0 3a1 1 0 0 1 1 1a1 1 0 0 1-1 1a1 1 0 0 1-1-1a1 1 0 0 1 1-1z"
              fill="currentColor"
            ></path>
          </svg>
        ),
      },
      {
        name: "Dark chocolate",
        icon: (
          <svg
            aria-hidden="true"
            role="img"
            className="w-full h-full"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M10 10a2 2 0 0 1-2 2a2 2 0 0 1-2-2V8c0-.63.1-1.23.27-1.8L10 9.93V10m2-8c.74 0 1.44.13 2.09.38L11.97 6c-.83 0-1.53.5-1.82 1.25L7.24 4.34A6.008 6.008 0 0 1 12 2m5.76 4.31L14 10.07V8c0-.38-.1-.73-.28-1.03l2.11-3.59a5.98 5.98 0 0 1 1.93 2.93m.24 6.78l-4 4V12.9l4-4v4.19M18 20a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-.09l4-4V20z"
              fill="currentColor"
            ></path>
          </svg>
        ),
      },
      {
        name: "Exercise",
        icon: (
          <svg
            aria-hidden="true"
            role="img"
            className="w-full h-full"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M13 2a2 2 0 1 0 0 4c1.11 0 2-.89 2-2a2 2 0 0 0-2-2M4 7v2h6v6l-5.07 5.07l1.41 1.43l6.72-6.73L17 17.13V21h2v-4.43c0-.36-.18-.68-.5-.86L15 13.6V9h6V7H4z"
              fill="currentColor"
            ></path>
          </svg>
        ),
      },
    ],
  },
];

export default happiness;
